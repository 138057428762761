import React from 'react';
import { ActionBlocker, ActionBlockerAppearance } from './ActionBlocker';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { isForcedPreview } from '../../selectors/isForcedPreview';
import { LocationContext } from '../../contexts/Location/LocationContext';

interface IPreviewActionBlockerProps {
  className?: string;
  appearance?: ActionBlockerAppearance;
  isActionAllowed?: boolean;
  content?: string;
}

export const PreviewActionBlockerContainer: React.FC<IPreviewActionBlockerProps> =
  (props) => {
    const { isPreview } = useEnvironment();
    const { t } = useTranslation();
    const { query } = React.useContext(LocationContext);
    const actionDisabled =
      (isPreview || isForcedPreview(query)) && !props.isActionAllowed;

    return (
      <ActionBlocker
        blockerContent={
          <div style={{ textAlign: 'center' }}>
            {props.content || t('action-blocker.preview-mode.default')}
          </div>
        }
        appearance={props.appearance}
        className={props.className}
        isActionDisabled={actionDisabled}
      >
        {props.children}
      </ActionBlocker>
    );
  };
