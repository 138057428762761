import React from 'react';

import { classes } from './CompletedIcon.st.css';

export const CompletedIcon: React.FC = () => {
  return (
    <div className={classes.root}>
      <span className={classes.rootCircle}></span>
    </div>
  );
};
