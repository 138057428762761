import React from 'react';

import { classes } from './RunningIcon.st.css';

export const RunningIcon: React.FC = () => {
  return (
    <div className={classes.root}>
      <span className={classes.rootCircle}></span>
    </div>
  );
};
